.footerItem {
  border-top-width: 1px;
  border-top-style: solid;
  display: flex;
  gap: 30px;
  padding-top: 26px;
  flex-direction: row;
}

@media (max-width: 767px) {
  .footerItem {
    gap: 10px;
  }
}
