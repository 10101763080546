.layout {
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media (max-width: 767px) {
  .layout {
    max-width: 300px;
  }
}
