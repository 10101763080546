.cardContainer {
  display: flex;
  // overflow: scroll;
  //   scroll-snap-type: x mandatory;
  //   padding: 50vw; // Adjust for desired centering offset
  // padding-left: 50vw;
  // padding-right: 50vw;
  width: 100%;
  flex-direction: row;
  gap: 40px;
  overflow-x: scroll;
  overflow-y: scroll;

  // Center the first card on page load
  &:before {
    content: "";
    display: block;
    width: 50vw; // This should match the horizontal padding to center the first card
  }
  &:after {
    content: "";
    display: block;
    width: 50vw; // This should match the horizontal padding to center the first card
  }
}
.cardContainerAuto {
  display: flex;
  // overflow: scroll;
  //   scroll-snap-type: x mandatory;
  //   padding: 50vw; // Adjust for desired centering offset
  // padding-left: 50vw;
  // padding-right: 50vw;
  width: 100%;
  flex-direction: row;
  gap: 40px;
  // overflow-x: scroll;
  // overflow-y: scroll;
  transition: transform 1s linear;
  // width: max-content; /* Ensure the width fits the content */
}
.carouselContainer {
  display: flex;
  overflow: hidden;
  width: 100%; /* Adjust to your desired width */
  position: relative;
  :hover {
    cursor: pointer;
  }
}
@media (max-width: 767px) {
  .cardContainerAuto {
    flex-direction: column;
  }
  .cardContainer {
    flex-wrap: wrap;
    overflow-y: scroll;
  }
}

/* Optional: Styling for smooth animation */
@keyframes autoScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
