.button {
  all: unset; /* Remove all styles */
  display: inline-block; /* Make button behave like inline text */
  cursor: pointer; /* Show pointer cursor on hover */
  background: none; /* Remove default background */
  border: none; /* Remove default border */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  font: inherit; /* Inherit font styles from parent */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */

  // add new styles
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  background-color: var(--Primary-Green, #d6a553);
}

.button:hover {
  background-color: #cfa154;
}
