.layout {
  display: flex;
  padding: 33px 100px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .layout {
    padding: 33px 33px;
  }
}
