.headerlayout {
  display: flex;
  flex-direction: column;
  max-width: 852px;
  align-self: center;
  background-color: aqua;
}

.lottie {
  position: relative;
}
