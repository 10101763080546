.layout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-direction: column;
}

@media (max-width: 767px) {
  .layout {
    flex-wrap: wrap;
  }
}
