@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400; /* Regular weight */
}

@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500; /* Medium weight */
}

@font-face {
  font-family: "Satoshi";
  src: url("../assets/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700; /* Bold weight */
}

@font-face {
  font-family: "AttenNew";
  src: url("../assets/fonts/Atten-New-Bold.ttf") format("truetype");
  font-weight: 700; /* Bold weight */
}
