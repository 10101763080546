.text {
  font-size: 50px;
}

.blogContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.blogCard {
  perspective: 1000px;
  width: 400px; // Adjust width as needed
  position: relative; // Needed for absolute positioning of child elements
  cursor: pointer;
}

.cardInner {
  position: relative;
  width: 100%;
  height: 200px; // Set a fixed height for the cards
  transition: transform 0.6s ease; // Smooth transition
  transform-style: preserve-3d;
}

.blogCard:hover .cardInner {
  transform: rotateY(180deg); // Flip on hover
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional shadow for card effect
}

.cardFront {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; // Background for front side
}

.cardFront img {
  width: 100%;
  height: 100%; // Ensures image scales properly
}

.cardBack {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; // Background for back side
  transform: rotateY(180deg);
  text-align: center;
}

.blogPost {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: #fff;
  overflow-wrap: break-word; // Allow long words to wrap
  word-wrap: break-word; // For older browsers
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: for some depth

  & .title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }

  & .metadata {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;

    & .author {
      margin-right: 10px;
    }

    & .date {
      margin-left: 10px;
    }
  }

  & .content {
    font-size: 1rem;
    line-height: 1.6;

    blockquote {
      margin: 20px 0;
      padding: 10px 20px;
      background-color: #f9f9f9;
      border-left: 5px solid #ccc;
      font-style: italic;
    }

    /* You can remove the previous styles for pre and code */
  }
}

.inlineCode {
  background-color: #e7f3fe; /* Background for inline code */
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: bold; /* Make it bold */
}
