/* IframeComponent.scss */

.iframe-container {
  width: 100%;
  height: 700px;
  overflow: hidden;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
