.faq {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
}

.faqHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  cursor: pointer;
  font-weight: bold;
}
.logoOpen img {
  transition: transform 0.5s ease;
  transform: rotate(180deg);
}
.logoClosed img {
  transition: transform 0.5s ease;
  transform: rotate(0deg);
}

.faqContent {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  padding: 0;
  padding-top: 39px;
}

.faqContentOpen {
  max-height: 200px; /* Adjust based on your content height */
  opacity: 1;
}

@media (max-width: 767px) {
  .faqHeader {
    padding-top: 20px;
  }
  .faqContent {
    margin-bottom: 20px;
  }
}
