.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdownToggle {
  padding: 0px;
  border: none; /* Removed border for link feel */
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent; /* Transparent background for link feel */
  color: inherit; /* Inherit text color */
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: rgba(240, 240, 240, 0.5); /* Light hover effect */
  }
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 5px;
  opacity: 0; /* Start hidden for transition */
  transition: opacity 0.2s ease, transform 0.2s ease;
  transform: translateY(-10px); /* Slightly shift up */

  &.open {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to normal position */
  }
}

.dropdownOption {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(240, 240, 240, 0.5); /* Hover effect for options */
  }
}
