@import "./../../styles/variables.scss";

.default {
  text-align: center;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
}

.link {
  font-size: 16px;
  font-weight: 700;
}

.button {
  font-size: 18px;
  font-weight: 700;
}

.bigHeader {
  font-feature-settings: "liga" off, "clig" off;
  font-size: 140px;
  font-weight: 700;
}

.header {
  font-feature-settings: "liga" off, "clig" off;
  font-size: 60px;
  font-weight: 700;
}

.subTitle-spaced {
  font-size: 30px;
  font-weight: 700;
  line-height: 64px;
}

.subTitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.footerTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.detailsCardTitle {
  font-size: 40px;
  font-weight: 700;
  line-height: 168%;
}

.detailsCardSubTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 168%;
}

.ratingFooterTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 5.714px;
  text-transform: uppercase;
}

.headerMedium {
  font-size: 48px;
  font-weight: 700;
  line-height: 40.907px;
}
.headerSmall {
  font-size: 30px;
  font-weight: 700;
  line-height: 148%;
}

.subTitleMedium {
  font-size: 20px;
  font-weight: 500;
  line-height: 148%;
}

.bulletLi {
  font-size: 14px;
  font-weight: 700;
  line-height: 148%;
}

.bulletLiContent {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.bulletLiFirst {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.tabListTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 35.52px;
}

.cardHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 40.91px;
}

.cardContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
}
.faqTitle {
  font-size: 30px;
  font-weight: 400;
  line-height: 34px;
}
.faqContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.footerDataList {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
}

.footerLinksList {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.6px;
}

.footerLinksListContent {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tag {
  font-size: 12px;
  font-weight: 700;
  line-height: 148%;
}

.normal {
  font-size: 14px;
  font-weight: 500;
  line-height: 148%;
}

.bold {
  font-weight: 700;
}
