.image {
  max-width: 100%;
  height: auto;
  display: block;
}

.imageBackground {
  // position: absolute;
  width: 100%;
  height: 619px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Use 'contain' or '100% 100%' if needed */
  color: #fff; /* Text color, adjust based on your image */
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
}
.imageBackgroundMobile {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Use 'contain' or '100% 100%' if needed */
  color: #fff; /* Text color, adjust based on your image */
  display: flex;
  flex-direction: column;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .imageBackground {
    height: 300px; /* Adjust height for smaller screens */
  }
}
